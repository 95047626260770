import CONST from '@/utils/Constants';
import axios from 'axios/index';

export class ApiHelper {
    static getById(url, id) {
        return axios.get(ApiHelper.getUrlPrefix() + url + '/' + id);
    }

    static get(url, options = {params: null, headerType: null}) {
        return axios.get(ApiHelper.getUrlPrefix() + url, {
            headers: ApiHelper.getHeadersByOptions(options),
            params: options.params
        });
    }

    static getJSONFile(url, options = {params: null}) {
        return axios.get('/data/' + url, {
            headers: ApiHelper.getHeadersByOptions(options),
            params: options.params
        });
    }

    static post(url, data, options = {params: null, headersType: null}) {
        return axios.post(ApiHelper.getUrlPrefix() + url, data, {headers: ApiHelper.getHeadersByOptions(options)});
    }

    static put(url, data, options = {params: null, headersType: null}) {
        return axios.put(ApiHelper.getUrlPrefix() + url, data, {headers: ApiHelper.getHeadersByOptions(options)});
    }

    static delete(url, data) {
      return axios.delete(ApiHelper.getUrlPrefix() + url, {headers: ApiHelper.getHeaders()}, data);
  }

    static getUrlPrefix() {
        return process.env.VUE_APP_API_URL;
    }

    static getHeadersByOptions(options = {headersType: null}) {
        return (options.headersType === 'basic') ? ApiHelper.getBasicHeaders() : ApiHelper.getHeaders();
    }

    static getBasicHeaders(contentType) {
        let headers = {};
        // headers[CONST.KEY.REQUEST.APP_KEY] = process.env.VUE_APP_KEY;
        // headers[CONST.KEY.REQUEST.APP_SECRET] = process.env.VUE_APP_SECRET;
        headers[CONST.KEY.REQUEST.ACCEPT] = CONST.VALUE.REQUEST.ACCEPT_JSON;
        headers[CONST.KEY.REQUEST.CONTENT_TYPE] = contentType;

        return headers;
    }

    static getMultipartHeaders() {
        let headers = ApiHelper.getBasicHeaders(CONST.VALUE.REQUEST.ACCEPT_MULTIPART);

        headers[CONST.KEY.REQUEST.AUTHORIZATION] =
            CONST.KEY.REQUEST.BEARER +
            localStorage.getItem(CONST.KEY.AUTH.ACCESS_TOKEN);

        return headers;
    }

    static getHeaders() {
        let headers = ApiHelper.getBasicHeaders(CONST.VALUE.REQUEST.ACCEPT_JSON);

        headers[CONST.KEY.REQUEST.AUTHORIZATION] =
            CONST.KEY.REQUEST.BEARER +
            localStorage.getItem(CONST.KEY.AUTH.ACCESS_TOKEN);

        return headers;
    }

    static parseUrlWithParameters(url, params) {
        if (params)
            for (let i = 0; i < params.length; i++)
                for (let key in params[i])
                    url = url.replace(':' + key, params[i][key]);

        return url;
    }
}

export default ApiHelper;
