import CONST from "@/utils/Constants";
import Notification from "@/utils/Notification";

export const ErrorHelper = {

    defaultDuration: 5000,

    catch: function (error, _this) {
      if (error.response) {
        error = error.response.data.error;

        switch (error.code) {
            default:
              Notification.add(error.message, Notification.TYPE.ERROR, this.defaultDuration);
            break;
            case CONST.API.EXCEPTION.AUTH_EXPIRED:
              Notification.add(_this.$t("notifications.error.auth-expired"), Notification.TYPE.ERROR, this.defaultDuration);
              _this.$router.push(CONST.API.ROUTE.SESSION.LOGOUT, () => {
              });
            break;
            case CONST.API.EXCEPTION.INVALID_LOGIN:
              Notification.add(_this.$t("notifications.error.invalid-login"), Notification.TYPE.ERROR, this.defaultDuration);
            break;
            case CONST.API.EXCEPTION.VALIDATION_ERROR:
              // Exception for regular validation handling
              // This is done on component level
            break;
        }
      }
    }
};

export default ErrorHelper;
